/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { JSONMap as e } from "../../jsonMap.js";
import { property as n } from "./property.js";
function r(r, a = {}) {
  const t = r instanceof e ? r : new e(r, a),
    {
      alwaysWriteDefaults: l,
      default: o,
      ignoreUnknown: s = !0,
      name: i,
      nonNullable: u,
      readOnly: p = !1
    } = a;
  return n({
    type: s ? t.apiValues : String,
    json: {
      type: t.jsonValues,
      default: o,
      name: i,
      read: !p && {
        reader: t.read
      },
      write: {
        writer: t.write,
        alwaysWriteDefaults: l
      }
    },
    nonNullable: u,
    readOnly: p
  });
}
export { r as enumeration };